<template>
  <div class="container is-max-desktop mb-4">
    <FeedbackForm />
  </div>
</template>

<script>
import FeedbackForm from "../../components/forms/FeedbackForm.vue";

export default {
  components: {
    FeedbackForm,
  },
  computed: {
    //
  },
};
</script>
