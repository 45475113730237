<template>
  <!-- Tags -->
  <div class="box" style="min-height: 25vh">
    <div class="columns">
      <div class="column">
        <h1 class="title is-4">Tell us how we can improve</h1>
      </div>
    </div>
    <hr class="mt-2" />
    <!-- General information -->
    <div class="heading mb-4">Feedback Form</div>
    <div class="columns mb-4">
      <div class="column is-3">
        <div class="field">
          <label class="label">{{ fields.category.label }}</label>
          <div class="control">
            <div :class="`select is-fullwidth ` + fields.category.class">
              <select
                v-model="feedBack.category"
                @blur="validateField('category')"
              >
                <option
                  v-for="cat in categoryOptions"
                  :key="cat.value"
                  :value="cat.value"
                  :disabled="cat.disabled"
                >
                  {{ cat.text }}
                </option>
              </select>
            </div>
          </div>
          <p class="help is-danger">{{ fields.category.message }}</p>
        </div>
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column">
        <b-field
          :label="fields.message.label"
          :message="fields.message.message"
          :type="fields.message.class"
        >
          <b-input
            maxlength="300"
            type="textarea"
            placeholder="Explain breifly what we can do to improve"
            v-model="feedBack.message"
            @blur="validateField('message')"
          ></b-input>
        </b-field>
      </div>
    </div>
    <p>
      We value your feedback. Thank you and we will try to improve based on it.
    </p>

    <!-- Contact information -->
    <div class="columns mb-2 mt-2">
      <div class="column">
        <div class="buttons">
          <b-button type="is-primary" @click="performCreate">Submit</b-button>
          <b-button type="is-primary is-light" @click="cancelEdit"
            >Cancel</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      readOnly: false,
      feedBack: {
        category: "",
        message: "",
      },
      categoryOptions: [
        {
          value: "",
          text: "Select a category",
          // make unselected option disabled
          disabled: true,
        },
        {
          value: "1",
          text: "Feedback",
        },
        {
          value: "2",
          text: "Issue",
        },
      ],
      fields: {
        message: {
          label: "Message",
          class: "",
          error: false,
          message: "",
        },
        category: {
          label: "Feedback type",
          class: "",
          error: false,
          message: "",
        },
      },
    };
  },
  computed: {
    //
  },
  methods: {
    validateField(field) {
      if (field === "message") {
        if (!this.feedBack.message || this.feedBack.message.length < 5) {
          this.fields.message.class = "is-danger";
          this.fields.message.error = true;
          this.fields.message.message =
            "Message is required. Minimum 5 characters.";
        } else {
          this.fields.message.class = "";
          this.fields.message.error = false;
          this.fields.message.message = "";
        }
      } else if (field === "category") {
        if (!this.feedBack.category) {
          this.fields.category.class = "is-danger";
          this.fields.category.error = true;
          this.fields.category.message = "Feedback type is required";
        } else {
          this.fields.category.class = "";
          this.fields.category.error = false;
          this.fields.category.message = "";
        }
      } else {
        console.log("Unknown field");
      }
    },
    formIsValid() {
      let isValid = true;
      for (let field in this.fields) {
        if (this.fields[field].error) {
          isValid = false;
        }
      }
      return isValid;
    },
    async submitForm() {
      //create formdata
      const formData = new FormData();
      formData.append("message", this.feedBack.message);
      formData.append("category", this.feedBack.category);
      //make api call
      let response = await axios.post(`/api/feedbacks/`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      //return response
      return response;
    },
    performCreate() {
      //validate form
      for (let field in this.fields) {
        this.validateField(field);
      }
      //if form is valid
      if (this.formIsValid()) {
        // set loading to true
        this.$store.dispatch("setLoading", true);
        // submit form
        this.submitForm()
          .then((response) => {
            if (response.status === 201) {
              // toast to show feed back submitted successfully
              this.$buefy.toast.open({
                message: "Feedback submitted successfully. Thank you.",
                type: "is-success",
                duration: 3000,
              });
              // set loading to false
              this.$store.dispatch("setLoading", false);
              // reset form
              this.feedBack = {
                category: "",
                message: "",
              };
              //navigate to home page
              this.$router.push({
                name: "EmployerDashboard",
              });
              //scroll to top
              window.scrollTo(0, 0);
            } else {
              this.$buefy.toast.open({
                message: "Something went wrong. Please try again.",
                type: "is-danger",
                duration: 3000,
              });
            }
          })
          .catch((error) => {
            window.scrollTo(0, 0);
            let err = error.response.data;
            let i;
            for (i in err) {
              this.fields[i].class = "is-danger";
              this.fields[i].error = true;
              this.fields[i].message = err[i][0];
              this.$buefy.toast.open({
                message: err[i][0],
                type: "is-danger",
                duration: 6000,
                queue: true,
              });
            }
            this.$store.dispatch("setLoading", false);
          });
      } else {
        window.scrollTo(0, 0);
        this.$store.dispatch("setLoading", false);
        this.$buefy.toast.open({
          message: "Form contains errors. Please correct and try again.",
          type: "is-danger",
          queue: false,
          duration: 3000,
        });
      }
    },
    cancelEdit() {
      // navigate to previous route
      this.$router.go(-1);
    },
  },
  mounted() {
    //
  },
  created() {
    //
  },
};
</script>
